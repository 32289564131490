import { ApplicationType, StatusType } from "../types/Application";
import { displayToast } from "./toast";

export function handleChangeValue<T>(field: string, value: any, setState: React.Dispatch<React.SetStateAction<T>>) {
  setState((prevState: T) => {
    return { ...prevState, [field as keyof T]: value };
  });
}

export function handleChangeNestedValue<T>(field: string, nestedField: string, value: any, setState: React.Dispatch<React.SetStateAction<T>>) {
  setState((prevState: T) => {
    return { ...prevState, [nestedField as keyof T]: { ...prevState[nestedField as keyof T], [field as keyof T]: value } };
  });
}

export function handleChangeRowValue<T>(index: number, field: keyof T, value: any, setState: React.Dispatch<React.SetStateAction<T[]>>) {
  setState((prevList) => prevList.map((item: any, i: number) => (i === index ? { ...item, [field]: value } : item)));
}

export function handleChangeArrayValue<T extends Record<string, any>>(
  field: keyof T,
  value: any,
  index: number,
  state: T[],
  setState: React.Dispatch<React.SetStateAction<T[]>>
) {
  const newElements = [...state];
  newElements[index][field] = value;
  setState(newElements);
}

export const regexFloat = /^(?!([.]))\d*([.]\d{0,2})?$/;

export function getNumberStep(application: ApplicationType) {
  if (application.status === StatusType.IN_COHABITANTS) return 2;
  if (!application.form?.material) return 3;
  if (!application.note) return 4;
  if (!application.category) return 5;
  if (application.status === StatusType.IN_FILES || !application.person.dniFileFront) return 6;
  if (application.status === StatusType.COMPLETE) return 7;
  if (application.status === StatusType.SUCCESS) return 8;
  return 0;
}

export function areValuesEqual<T extends object>(actualObj: T, newObj: T) {
  const actualObjKeys = Object.keys(actualObj) as (keyof T)[];
  const newObjKeys = Object.keys(newObj) as (keyof T)[];

  for (const key of newObjKeys) {
    if (!actualObjKeys.includes(key) || actualObj[key] !== newObj[key]) {
      return false;
    }
  }

  return true;
}

export function toNameFormat(text: string) {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const formatToCurrency = new Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS" });

export const downloadFile = (base64Data: string) => {
  try {
    const binaryString = window.atob(base64Data);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL, "_blank");
    setTimeout(() => {
      URL.revokeObjectURL(fileURL);
    }, 100);
  } catch (error) {
    displayToast({
      message: "Falló la generación del documento. Inténtelo nuevamente",
      statusCode: 400,
    });
    console.error("Error al descargar el PDF:", error);
  }
};
