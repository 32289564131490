import { jwtDecode } from "jwt-decode";
import { UserJwtType } from "../types/User";

const localStorageItem = window.localStorage.getItem("jwt");
export const jsonWebToken = () => {
  if (localStorageItem) {
    try {
      return JSON.parse(localStorageItem);
    } catch {
      return null;
    }
  } else {
    return null;
  }
};

export const userData = () => {
  return jwtDecode(jsonWebToken()) as UserJwtType;
};

type HeaderType = {
  headers: {
    Authorization: string;
    "Content-Type"?: string;
  };
};

export const authHeader = (type?: string) => {
  const token = jsonWebToken();
  let headers: HeaderType = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (type === "m/fd") {
    headers.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
  }

  if (type === "a/j") {
    headers.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }
  return headers;
};
