import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, InputLabel, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { FileSubmit } from "../../../components/FileSubmit";
import useAxios from "../../../hooks/useAxios";
import endpoints from "../../../helpers/endpoints";
import { NewRequestContext } from "../../../contexts/NewRequestContext";
import { FileType, GetResourceType } from "../../../types/Resource";
import { ApplicationType, StatusType } from "../../../types/Application";
import { useApiMutation } from "../../../helpers/apiClient";

export const FileUploading = () => {
  const { idApplication } = useParams();
  const { setActiveStep, application, person } = useContext(NewRequestContext);
  const dniFileFront = person.dniFileFront;
  const dniFileBack = person.dniFileBack;
  const [fileLoading, setFileLoading] = useState(false);

  const { data: resourcesData } = useAxios<GetResourceType>({
    url: `${endpoints.getResourcesByApplicationId}=${idApplication}`,
    method: "get",
  });

  const completeApplication = useApiMutation<ApplicationType, {}>({
    url: `${endpoints.completeApplication}/${application.id}`,
    msgOnSuccess: "Solicitud completada",
    method: "put",
    options: {
      onSuccess: () => {
        setActiveStep(7);
      },
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (application.status === StatusType.IN_FILES || application.status === StatusType.IN_APP_DATA) {
      completeApplication.mutate({});
    } else {
      setActiveStep(7);
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Cargue los archivos correspondientes
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Box display="flex">
              <InputLabel sx={{ color: "black" }} required>
                Frente del DNI (o frente y dorso en un mismo archivo)
              </InputLabel>
            </Box>
            <FileSubmit setFileLoading={setFileLoading} type={FileType.DNI_FRONT} fileName={dniFileFront} />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex">
              <InputLabel sx={{ color: "black" }}>Dorso del DNI</InputLabel>
            </Box>
            <FileSubmit setFileLoading={setFileLoading} type={FileType.DNI_BACK} fileName={dniFileBack} />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex">
              <InputLabel sx={{ color: "black" }}>Presupuesto</InputLabel>
            </Box>
            <FileSubmit
              setFileLoading={setFileLoading}
              type={FileType.BUDGET}
              fileName={resourcesData && resourcesData[FileType.BUDGET] && resourcesData[FileType.BUDGET][0].name}
            />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex">
              <InputLabel sx={{ color: "black" }}>Receta médica</InputLabel>
            </Box>
            <FileSubmit
              setFileLoading={setFileLoading}
              type={FileType.PRESCRIPTION}
              fileName={resourcesData && resourcesData[FileType.PRESCRIPTION] && resourcesData[FileType.PRESCRIPTION][0].name}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ color: "black" }}>Otra documentación</InputLabel>
            <FileSubmit
              setFileLoading={setFileLoading}
              type={FileType.OTHER}
              fileName={resourcesData && resourcesData[FileType.OTHER] && resourcesData[FileType.OTHER][0].name}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            loading={fileLoading}
            onClick={() => {
              setActiveStep((activeStep) => activeStep - 1);
            }}
            sx={{ mt: 3, ml: 1 }}
            tabIndex={-1}
          >
            Atrás
          </LoadingButton>
          <LoadingButton loading={fileLoading} /* disabled={!person.dniFileFront} */ variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
            Siguiente
          </LoadingButton>
        </Box>
      </form>
    </React.Fragment>
  );
};
